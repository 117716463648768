.control-panel{
    display: flex;
    justify-content: space-between;
}
.control-panel .chips{
    display: flex;
    justify-content: space-between;
}

.chips > div {
    border: solid grey 1px;
    border-radius: 2px;
    position: relative;
    padding-top: 0;
    overflow: hidden;
}
.chips > div:before {
    position: absolute;
    left: 5px;
    top: 1px;
}
.chips .chips-filters > div:nth-child(1) {
    margin-left: 60px;
}
.chips .chips-sorts div:nth-child(1) {
    margin-left: 80px;
}
.chips .chips-filters:before {
    content: 'фильтры';
}
.chips .chips-sorts:before {
    content: 'сортировка';
}

.edit.yes{
    cursor: pointer;
}

.card-name-count{
    font-size: 16px;
    float: right;
    padding-right: 10px;
    color: green;
}
.card-name-count.over-limit{
    color: red;
}

.img-cont > img{
    margin: 0 1px;
}

.filters .active {
    border: 2px solid #106a00;
    background: #106a00;
}
