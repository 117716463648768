.navbar{
    background-color: rgb(177, 177, 177) !important;
}
html,
body,
main.container-fluid{
    background-color: rgb(210, 210, 210) !important;
}

.loading {
    position: absolute;
    background: url('../../common/spinner.gif') no-repeat;
    background-size: contain;
    width: 80px;
    height: 30px;
    display: inline-block;
}

.table.nowrap{
    min-height: 300px;
}
.table.nowrap td{
    white-space: nowrap;
}
.table.nowrap th{
    vertical-align: baseline;
}
.table.nowrap.odd tr:nth-child(even){
    background: rgb(244, 244, 244);
}
.table-field-filter-block{
    position: absolute;
    background: rgb(244, 244, 244);
    padding: 5px;
    border: solid 1px rgb(180, 180, 180);
    border-radius: 5px;
    margin-top: -10px;
}
.table-field-filter-block input{
    padding: 0px 6px;
}
.table-field-filter-common-block{
    min-width: 100px;
    position: relative;
}
.table-field-filter-common-block button.btn{
    padding: 0 3px;
}
.table-field-filter-common-block .btns{
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}
.table-field-filter-common-block .btns button{
    line-height: 18px;
}
.filter-chips{
    display: flex;
    list-style: none;
    margin: 0;
    height: 30px;
}
.filter-chips li{
    padding: 0 3px;
    background: azure;
    border-radius: 5px;
    margin: 3px;
    cursor: pointer;
}
.filters-total-count{
    margin: 0;
    float: right;
}
.do-replication-orders{
    margin: 0;
    line-height: 10px;
    position: absolute;
    top: 19px;
    right: 20px;
}
.orders-loading{
    position: fixed;
}

main{
    padding-bottom: 10px;
}

.ag-simple-filter-body-wrapper .ag-filter-condition,
.ag-simple-filter-body-wrapper [ref="eOptions2"],
.ag-simple-filter-body-wrapper [ref="eCondition2Body"]
{
    display: none;
}

.page-target-buttons .active{
    border: 2px #106a00 solid;
    background: #106a00;
}

#photo-preview img{
    position: absolute;
    z-index: 5;
}

.btn-group-xs > .btn, .btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}

.chip {
    display: inline-block;
    padding: 0 7px;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 25px;
    background-color: #f1f1f1;
}
.chip .closebtn {
    padding-left: 5px;
    color: #999;
    float: right;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
}
