.card{
    width: 18rem;
    background: rgb(58, 113, 161);
    margin: 15px;
    color: white;
}
.card.not-active{
    background: rgb(166 117 117);
}

.card-text.time{
    margin-top: 16px;
}

.card-text .text-muted{
    float: right;
    color: rgb(207, 207, 207) !important;
}

.loading{
    right: -5px;
    top: -5px;
}
